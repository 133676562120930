import { Box, Button, CircularProgress, Dialog, Popover, Tooltip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import ConfirmationDialog, { ConfirmationDialogProps } from 'components/Dialogs/ConfirmationDialog'
import _ from 'lodash'
import { useRecoilValueLoadable } from 'recoil'
import { DataGridPro } from '@mui/x-data-grid-pro'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { LabelApprovalWorkflowInstance, LabelApprovalWorkflowStep } from 'lib/graphql/types'
import { useMutation } from '@apollo/client'
import { RESTART_LABEL_APPROVAL_WORKFLOW_INSTANCE } from 'lib/graphql/restartLabelApprovalWorkflowInstance.ts'
import React, { useState } from 'react'
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import AlertDialog from 'components/Dialogs/AlertDialog.tsx'
import { REJECT_LABEL_APPROVAL_WORKFLOW_INSTANCE } from 'lib/graphql/rejectLabelApprovalWorkflowInstance.ts'
import { ROLES } from 'lib/rbac.tsx'
import { currentUser } from 'store/user.ts'


function formatDateTime(v: Date) {
  return v ? dayjs(v).format('M/D/YYYY h:mm A') : ''
}

type Props = Omit<ConfirmationDialogProps, 'title' | 'description'> & {
  idToReview: string
  setIsOpen: (b: boolean) => void
  updateStep: (data: { status: string; rejectionReason?: string | undefined }) => Promise<void>
  workflowInstance: LabelApprovalWorkflowInstance
  metadata: any
  buttonsDisabled: boolean
  buttonsShow: boolean
  handleReload: () => void
  canRestart: boolean
  maxImageHeight: string
}

export const ReviewLabel = ({
  metadata,
  open,
  handleClose,
  handleConfirm,
  handleReload,
  reason,
  setReason,
  workflowInstance,
  setIsOpen,
  updateStep,
  idToReview,
  buttonsDisabled,
  buttonsShow,
  canRestart,
  maxImageHeight,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [showCaret, setShowCaret] = useState(false)
  const openAddressPopover = Boolean(anchorEl)
  const [address, setAddress] = useState<any>({})
  const [zoomDialog, setZoomDialog] = useState<string>()
  const [labelHover, setLabelHover] = useState<boolean>(false)
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false)
  const [alert, setAlert] = useState<{
    severity: 'error' | 'warning' | 'info' | 'success'
    description: string
  }>()

  const user = useRecoilValueLoadable(currentUser)

  const role = user?.contents?.role

  const handleClickAddress = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseAddress = () => {
    setAnchorEl(null)
  }

  const [restartWorkflowMutation, { loading }] = useMutation(RESTART_LABEL_APPROVAL_WORKFLOW_INSTANCE)

  const restartWorkflow = async (id: string) => {
    await restartWorkflowMutation({
      variables: {
        id,
      },
      onCompleted: () => {
        setAlert({
          description:
            'Your request to restart this workflow has been received. Please check again in a few minutes to see the new workflow.',
          severity: 'success',
        })
        setOpenAlertDialog(true)
      },
      onError: () => {
        setAlert({
          description:
            'Your request to restart this workflow could not be processed. Please try again later or contact support',
          severity: 'error',
        })
        setOpenAlertDialog(true)
      },
    })
  }

  const [rejectWorkflowMutation] = useMutation(REJECT_LABEL_APPROVAL_WORKFLOW_INSTANCE)

  const rejectWorkflow = async () => {
    await rejectWorkflowMutation({
      variables: {
        id: idToReview,
      },
      onCompleted: () => {
        setAlert({
          description: 'Your request to reject this workflow has been processed.',
          severity: 'success',
        })
        setOpenAlertDialog(true)
      },
      onError: (data) => {
        console.log(data.message)
        setAlert({
          description: `${data.message}\nYour request to reject this workflow could not be processed. Please try again later or contact support`,
          severity: 'error',
        })
        setOpenAlertDialog(true)
      },
    })
  }

  const isAdmin = role === ROLES.ADMIN

  return (
    <>
      <AlertDialog
        severity={alert?.severity}
        description={alert?.description}
        open={openAlertDialog}
        handleClose={() => {
          setOpenAlertDialog(false)
          handleReload()
        }}
      />
      <ConfirmationDialog
        open={open}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        reason={reason}
        setReason={setReason}
        title='Request Changes to Private Label'
        description='Please provide a reason.'
      />
      {/*/!*Horizontial line*!/*/}
      {/*<Box*/}
      {/*  sx={{*/}
      {/*    width: { xs: 0, md: '100%' },*/}
      {/*    display: 'flex',*/}
      {/*    justifyContent: 'stretch',*/}
      {/*    paddingBottom: '16px',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Box sx={{ backgroundColor: '#5f5f5f', width: '100%', height: '1px', alignItem: 'center' }}></Box>*/}
      {/*</Box>*/}

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: '16px',
          alignItems: 'stretch',
          width: '100%',
        }}
      >
        <Box
          sx={{
            padding: '16px',
            flexBasis: '10%',
            flexGrow: 1,
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <div>
              <b>
                {metadata.order?.customer.first_name} {metadata.order?.customer.last_name}
              </b>
            </div>
            <div>{metadata.order?.customer.email}</div>
            <div>{metadata.order?.customer.phone}</div>
            <div>
              <Button
                id='basic-button'
                variant={'text'}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={(ev) => handleClickAddress(ev)}
                onMouseEnter={() => {
                  setAddress({
                    name: metadata.order?.billing_address?.name,
                    company: metadata.order?.billing_address?.company,
                    address1: metadata.order?.billing_address?.address1,
                    address2: metadata.order?.billing_address?.address2,
                    citystate: `${metadata.order?.billing_address?.city}, ${metadata.order?.billing_address?.province_code} ${metadata.order?.billing_address?.zip}`,
                  })
                  setShowCaret(true)
                }}
                onMouseLeave={() => {
                  setShowCaret(false)
                }}
                sx={{
                  textTransform: 'none',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ height: '20px', alignSelf: 'center' }}>Billing Address</Box>
                <Box sx={{ height: '20px', alignSelf: 'center' }}>
                  {showCaret ? <KeyboardArrowDownSharpIcon /> : null}
                </Box>
              </Button>
            </div>
            <div>
              <Button
                id='basic-button'
                variant={'text'}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={(ev) => handleClickAddress(ev)}
                onMouseEnter={() => {
                  setAddress({
                    name: metadata.order?.shipping_address?.name,
                    company: metadata.order?.shipping_address?.company,
                    address1: metadata.order?.shipping_address?.address1,
                    address2: metadata.order?.shipping_address?.address2,
                    citystate: `${metadata.order?.shipping_address?.city}, ${metadata.order?.shipping_address?.province_code} ${metadata.order?.shipping_address?.zip}`,
                  })
                  setShowCaret(true)
                }}
                onMouseLeave={() => setShowCaret(false)}
                sx={{
                  textTransform: 'none',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ height: '20px', alignSelf: 'center' }}>Shipping Address</Box>
                <Box sx={{ height: '20px', alignSelf: 'center' }}>
                  {showCaret ? <KeyboardArrowDownSharpIcon /> : null}
                </Box>
              </Button>
            </div>
          </Box>
        </Box>
        <Popover
          id='popover'
          anchorEl={anchorEl}
          open={openAddressPopover}
          onClose={handleCloseAddress}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          slotProps={{
            paper: {
              style: {
                borderRadius: '10px',
              },
            },
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '3px', padding: '20px' }}>
            <div>{address?.name}</div>
            <div>{address?.company}</div>
            <div>{address?.address1}</div>
            <div>{address?.address2}</div>
            <div>{address?.citystate}</div>
          </Box>
        </Popover>

        <Box sx={{ padding: '16px', flexBasis: '30%', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant={'body1'}>
                Order:&nbsp;<b>{metadata.order?.name}</b>
              </Typography>
              <Tooltip title={`Instance ${metadata.label?.instanceId}`}>
                <MoreHorizIcon />
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                flexDirection: { xs: 'column', xl: 'row' },
              }}
            >
              <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
                <Typography variant={'body1'}>
                  SKU:&nbsp;
                  <b>{metadata.label?.sku}</b>
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
                <Typography variant={'body1'}>
                  Product:&nbsp;
                  <b>{metadata.label?.brandedName}</b>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                flexDirection: { xs: 'column', xl: 'row' },
              }}
            >
              <Typography variant={'body1'}>
                PL Name:&nbsp;
                <b>
                  {metadata.label?.productName ||
                    metadata.item?.properties.find((x: any) => x.name === 'Product Name').value}
                </b>
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                width: '100%',
                alignItems: 'center',
                flexDirection: { xs: 'column', xl: 'row' },
              }}
            >
              <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
                Status:&nbsp;<b>{_.startCase(workflowInstance.status)}</b>
              </Box>
              <Box sx={{ flexGrow: 1, flexBasis: 0, gap: '8px', display: 'flex', flexDirection: 'column' }}>
                {canRestart && !['Rejected', 'Approved', 'Cancelled'].includes(workflowInstance.status) ? (
                  <Button
                    variant={'outlined'}
                    size={'small'}
                    disabled={loading || !isAdmin}
                    onClick={() => restartWorkflow(workflowInstance.id)}
                  >
                    Restart workflow{' '}
                    {loading && (
                      <>
                        &nbsp;
                        <CircularProgress size={12} />
                      </>
                    )}
                  </Button>
                ) : (
                  <></>
                )}
                {canRestart && workflowInstance.status === 'ChangeRequested' ? (
                  <Button
                    variant={'outlined'}
                    size={'small'}
                    disabled={loading || !isAdmin}
                    onClick={() => rejectWorkflow()}
                  >
                    Reject and Notify Customer{' '}
                    {loading && (
                      <>
                        &nbsp;
                        <CircularProgress size={12} />
                      </>
                    )}
                  </Button>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
            {workflowInstance.rejectionReason && (
              <div>
                Reason:&nbsp;<b>{_.startCase(workflowInstance.rejectionReason)}</b>&nbsp;
              </div>
            )}
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                width: '100%',
                flexDirection: { xs: 'column', xl: 'row' },
                paddingBottom: '15px',
              }}
            >
              {metadata.label?.labelFactsVersion && (
                <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
                  <Typography variant={'body1'}>
                    Facts: <b>v{metadata.label?.labelFactsVersion}</b>
                  </Typography>
                </Box>
              )}
              {metadata.label?.revisionNumber && (
                <Box sx={{ flexGrow: 1, flexBasis: 0 }}>
                  <Typography variant={'body1'}>
                    Revision:&nbsp;<b>{metadata.label?.revisionNumber}</b>
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          {buttonsShow && (
            <div>
              <Box
                sx={{ display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'space-around' }}
              >
                <Button
                  disabled={buttonsDisabled}
                  variant='outlined'
                  color={'error'}
                  onClick={() => setIsOpen(true)}
                >
                  Request Change
                </Button>
                <Button
                  disabled={buttonsDisabled}
                  variant='contained'
                  color={'success'}
                  onClick={() => updateStep({ status: 'Approved' })}
                >
                  Approve
                </Button>
              </Box>
            </div>
          )}
        </Box>

        <Box
          sx={{
            padding: '2px',
            flexBasis: '50%',
            flexGrow: 1,
            overflow: 'auto',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateRows: 'auto 1f auto',
              flexDirection: { xs: 'column', md: 'row' },
              // flexGrow: '0 1 auto',
              width: '100%',
            }}
          >
            <DataGridPro
              sx={{ border: '0px', overflowX: 'scroll', width: '100%' }}
              columns={[
                {
                  field: 'thisStep',
                  headerName: '',
                  width: 10,
                  align: 'center',
                  disableColumnMenu: true,
                  renderCell: (x) => (x.value ? <ArrowRightIcon /> : null),
                },
                {
                  field: 'stepNumber',
                  headerName: '',
                  width: 10,
                  align: 'center',
                  disableColumnMenu: true,
                },
                {
                  field: 'status',
                  headerName: 'Decision',
                  width: 150,
                  disableColumnMenu: true,
                  renderCell: (x) => _.startCase(x.value),
                },
                { field: 'email', headerName: 'Reviewer', width: 220, disableColumnMenu: true },
                {
                  field: 'updatedAt',
                  headerName: 'Last updated',
                  // flex: 0.7,
                  width: 300,
                  disableColumnMenu: true,
                  renderCell: (x) => formatDateTime(x.value),
                },
              ]}
              autoHeight={true}
              hideFooter={true}
              rows={_.sortBy(
                workflowInstance.steps.map((step: LabelApprovalWorkflowStep) => {
                  return {
                    id: step.id,
                    stepNumber: step.stepNumber,
                    thisStep: step.id === idToReview,
                    email: step.assignedTo.email,
                    status: step.status !== 'Received' ? step.status : null,
                    updatedAt: step.status !== 'Received' ? step.updatedAt : null,
                  }
                }),
                ['stepNumber']
              )}
            />
          </Box>
        </Box>
      </Box>

      {/*Label*/}
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <Box
          sx={{
            // overflow: 'auto',
            width: 'contain',
            scale: labelHover ? '1' : '.98',
            transition: '300ms',
            marginTop: '10px',
            marginBottom: '30px',
            boxShadow:
              'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
          }}
        >
          <img
            alt={''}
            style={{ maxWidth: '100%', maxHeight: maxImageHeight ?? '300px' }}
            onMouseEnter={() => setLabelHover(true)}
            onMouseLeave={() => setLabelHover(false)}
            onClick={() =>
              setZoomDialog(
                metadata.label?.instanceImage3DUrl ||
                  metadata.item?.properties.find((x: any) => x.name === '__job3D').value
              )
            }
            src={
              metadata.label?.instanceImage3DUrl ||
              metadata.item?.properties.find((x: any) => x.name === '__job3D').value
            }
          />
        </Box>
      </Box>
      <Dialog open={!!zoomDialog} fullWidth={true} maxWidth={'xl'} onClick={() => setZoomDialog(undefined)}>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <img
            src={zoomDialog}
            alt={''}
            style={{
              alignSelf: 'center',
              width: '100%',
              maxWidth: '-webkit-fill-available',
              maxHeight: '-webkit-fill-available',
            }}
          />
        </Box>
      </Dialog>
    </>
  )
}
